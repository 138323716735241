// import Image from "next/image";
import clsx from "clsx";
import Style from './Picture.module.scss'
import { blurImage } from 'lib/Constants/blurImage';
import Image from "next/image";
import Link from "next/link";

function Picture({ 
    width = 800, 
    height = 800, 
    alt = "", 
    mobile = null, 
    mobileWidth = 480,
    desktop = null, 
    className = "",
    ctaLink = null,
    priority = false
}){
    const pictureContent = (
        <picture className={clsx(className, Style.block)}>
            <source srcSet={desktop ?? blurImage} media="(min-width: 600px)" />
            <source srcSet={mobile ?? blurImage} media="(max-width: 600px)" />
            <Image 
                srcSet={`${mobile ?? blurImage} ${mobileWidth}w, ${desktop ?? blurImage} ${width}w`}
                src={mobile ?? desktop ?? blurImage}
                sizes={`(max-width: 600px) ${mobileWidth}px, ${width}px`}
                width={width}
                height={height}
                placeholder={blurImage}
                className={Style.image}
                priority={priority}
                alt={alt}
            />
        </picture>
    );

    return (
        <>
            {ctaLink?.length ? (
                <Link href={ctaLink}>
                    {pictureContent}
                </Link>
            ) : (
                pictureContent
            )}
        </>
    );
}

export default Picture;
